.generate-pdf-container {
  text-align: center;
  margin-top: 10px;
}

.generate-pdf-button {
  background-color: #12282A;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Inter';
  // font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: white;
    color: #12282A;
  }

  &:focus {
    outline: none;
  }
}
