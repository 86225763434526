.footer {
	background: #222;
	color: white;
	text-align: center;
	padding: 20px;

	.footer-socials {
			margin-bottom: 10px;
			a {
					color: white;
					margin: 0 10px;
					font-size: 24px;
					transition: transform 0.3s;

					&:hover {
							transform: scale(1.2);
							color: #ddd;
					}
			}
	}

	.footer-links {
			button {
					background: none;
					border: none;
					color: white;
					margin: 5px 10px;
					cursor: pointer;
					font-size: 16px;
					text-decoration: underline;

					&:hover {
							color: #ddd;
					}
			}
			a{
				color: white;
			}
	}

	p {
			font-size: 14px;
			margin-top: 10px;
	}
}

/* Modal Styles */
.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background: white;
	color: #222;
	padding: 20px;
	border-radius: 8px;
	width: 90%;
	max-width: 500px;
	text-align: center;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

	/* Limitation de la hauteur et ajout d'un défilement */
  max-height: 80vh; /* Limite la hauteur à 80% de la hauteur de l'écran */
  overflow-y: auto; /* Ajoute un défilement vertical si le contenu dépasse */


	h2 {
			margin-bottom: 10px;
			font-size: 24px;
	}

	p {
			font-size: 16px;
			margin-bottom: 20px;
	}

	button {
			background: #222;
			color: white;
			padding: 10px 20px;
			border: none;
			border-radius: 5px;
			cursor: pointer;

			&:hover {
					background: #555;
			}
	}
}
.footer-visit-counter{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	
}
.footer-visit-counter-FaEye{
	text-align: center;
	margin-right: 5px;

}
@media (max-width: 768px) {
  .modal-content {
    padding: 15px; /* Réduit les marges internes pour économiser de l'espace */
    max-height: 70vh; /* Réduit encore plus la hauteur maximale */
  }

  .modal-content h2 {
    font-size: 20px; /* Taille de police réduite pour les titres */
  }

  .modal-content p {
    font-size: 14px; /* Taille de police réduite pour le texte */
  }
}
