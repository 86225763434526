// .adsenseAd{
// 	display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 50px;
//   width: 100%;
//   background-color: #12282A;
// 	margin-bottom: 15px;

//   &-text {
//     font-style: italic;
//     color: #FFFFFF;
//   }
// }
/* App.scss */
.adsense-ad {
  width: 300px;  /* Largeur fixe en pixels */
  height: 250px; /* Hauteur fixe en pixels */
  margin: 20px auto; /* Optionnel : Centrer l'annonce */
  background-color: #f0f0f0; /* Juste pour la visualisation, vous pouvez l'enlever */
}
