
.calculetteImmo{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	
	
	

	&-card{
		margin: 0.5em;
		min-width: 345px;
		height:max-content;
	}
}
.divMensualite{
	display: flex;
	

	&-text{
		background-color: #12282A;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 1em;
		border: solid 1px;
		width: 230px;
		height: 100px;
		color: #FFFFFF;
		font-weight: bold;
		margin-left: 2em;
	}
	&-text-versement {
    background-color: #12282A;
		justify-content: left;
    text-align: left;       // Alignement du texte à gauche
  }
	
}
.divFormMensualite{
	display: flex;
	flex-direction: column;
}
.formLabel{
display: flex;
justify-content: left;
align-items: center;


}
.typo{
padding-top: 10px;
font-style: italic;
}
.divMensualiteCard5{
	display: flex;
	flex-direction: column;
}
.divEffort{
	display: flex;
	

	&-text{
		background-color: #12282A;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 1em;
		border: solid 1px;
		width: 90%;
		height: auto;
		color: #FFFFFF;
		font-weight: bold;
		padding: 5px;
		margin-top: 15px;
		margin-bottom: 5px;
	}
}