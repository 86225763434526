.cookie-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cookie-modal h2 {
  margin-bottom: 10px;
}

.cookie-modal p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}

.cookie-buttons {
  display: flex;
  justify-content: space-between;
}

.cookie-buttons button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cookie-buttons button:hover {
  background-color: #0056b3;
}

.cookie-buttons button:focus {
  outline: none;
}
