.App {
  text-align: center;
  height: 100vh;

  &-footer{
    width: 100%;
    
  }
  
  
}

.App-header {
  background-color:#134E4A;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFFFFF;
  
  &__logo{
    display: flex;
    align-items: center;
    position: absolute;
    left: 1em;
    border-radius: 50%;
  }
}
.title-header{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 55%;
  height: 80px;
  width: 80%;
  margin-left: 55px;
}
.container-composants{
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.euroIcon{
  margin-top: 0.3em;
}

